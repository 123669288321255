<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>Update Circuit Status</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="dismiss()">X</ion-button>
      </ion-buttons>  
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <p>
      Circuit {{circuit.circuitId}} status is currently set to 
          <ion-text color="success" v-if="circuit.status">ON</ion-text>
          <ion-text color="danger" v-if="!circuit.status">OFF</ion-text> 
    </p>
    <ion-list v-if="circuit.status">
      <ion-item>
        <ion-label position="floating">Turn the Circuit Back ON after minutes</ion-label>
        <ion-input @ionChange="onTimedeltaChange()" min="1" v-model="timedelta" inputmode="numeric"></ion-input>
      </ion-item>
      <ion-item v-if="timedelta && timedelta > 0">
        The circuit will be set to ON {{ moment(expiry).fromNow() }} at {{ moment(expiry).format('MMMM Do YYYY, h:mm:ss a') }}
      </ion-item>
      <ion-button @click="setCircuitStatus(circuit.circuitId, false)" expand="block">Turn Circuit {{ circuit.circuitId }} OFF</ion-button>
    </ion-list>
    <ion-list v-if="!circuit.status">
      <ion-item>
        Are you sure that you want to turn Circuit {{ circuit.circuitId }} ON?
      </ion-item>
      <ion-button expand="block" @click="setCircuitStatus(circuit.circuitId, true)" >
        Turn it ON
      </ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonInput } from '@ionic/vue';
import { defineComponent } from 'vue';
import { modalController } from '@ionic/vue'
import moment from 'moment'
import { vedaService } from '../../services/vedaService'
import { userService } from '@/services/userService';

export default defineComponent({
  name: 'UpdateCircuitModal',
  props: {
    circuit: { type: Object },
  },
  created: function() {
    this.moment = moment
    this.timedelta = 24*60
    this.expiry = moment().add(this.timedelta, 'm').toDate()
  },
  data() {
    return {
      content: 'Content',
      timedelta: undefined,
      expiry: undefined
    }
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonInput },
  methods: {
      dismiss() {
          modalController.dismiss()
      },
      onTimedeltaChange() {
        this.expiry = moment().add(this.timedelta, 'm').toDate()
      },
      setCircuitStatus (circuitId, newStatus) {
        const status = newStatus? 'on': 'off'
        if(this.timedelta) {
          this.circuitTest(circuitId, status, this.expiry)
          vedaService.saveCircuitExpiry(circuitId, this.expiry)
        } else {
          this.circuitTest(circuitId, status)
        }
      },
      async circuitTest (circuitId, status, expiry) {
      console.log(`circuitTest:circuitId -> ${circuitId}, status -> ${status}, expiry -> ${expiry}`)
      if (!status || !circuitId) {
        // alert('Please select CircuitId and Status')
        userService.openToast('Please select CircuitId and Status', 'warning')
        return
      }
      const formattedDate = this.formatDateStringToIso(this.expiry)
      const response = await vedaService.circuitControlTest('forceDeviceCircuit', circuitId.toString(), status, formattedDate)
      if (response.data.status) {
        userService.openToast(response.data.data, 'success')
      }
      },
      formatDateStringToIso (expiry) {
        // TODO : Will break in future. Change before the year 10,000.
        console.log('CircuitTest:formatDateStringToIso:Local expiry:\n', expiry)
        if (expiry) {
          let date = new Date(expiry)
          date = date.toISOString().substr(0, 19).replace(/-/g, '').replace(/:/g, '') + 'Z'
          console.log('CircuitTest:formatDateStringToIso:UTC expiry:\n', date)
          return date
        }
      },
  }
});
</script>