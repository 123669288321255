<template>
  <div>
    <ion-list>
      <!-- For debugging purposes only
      <ion-list-header><ion-label></ion-label></ion-list-header>
      -->
      <ion-item>
        <!-- <ion-button @click="refresh()" slot="end">Refresh Circuit Status</ion-button> -->
        <ion-label><b>Circuit Test</b></ion-label>
        <ion-button fill="clear" slot="end" shape="round" size="small" @click="refreshCircuitStatus()">
          <ion-icon slot="icon-only" :icon="refreshOutline"></ion-icon>
        </ion-button>
      </ion-item>

      <ion-item v-for="c in circuits" :key="c.circuitId">
        <ion-label>
          Circuit {{ c.circuitId }} --
          <ion-text color="success" v-if="c.status">ON</ion-text>
          <ion-text color="danger" v-if="!c.status">
            CURRENTLY OFF <span v-if="c.offExpiry">will come back ON {{ moment(c.offExpiry).fromNow() }}</span>
          </ion-text>
        </ion-label>
        <ion-button @click="openUpdateCircuitModal(c)" slot="end">Change Status</ion-button>
        <!-- <ion-datetime @ionChange="setExpiryDate(c.circuitId, $event.detail.value)" placeholder="set expiry" display-format="D MMMM YYYY HH:mm:ss"></ion-datetime>
        <ion-toggle
          @ionChange="circuitStatusToogle(c.circuitId, c.status)"
          :checked="c.status"
          color="success">
        </ion-toggle> -->
      </ion-item>
    </ion-list>

    <!-- <button @click="getCircuitStatus()">Circuit Status - CLICK</button> -->
    <!--
      <ion-item>
        <ion-label>Circuit ID</ion-label>
        <ion-select :value="circuitId" placeholder="Select One" ok-text="Ok" cancel-text="Cancel" v-model="circuitId">
          <ion-select-option value="1">1</ion-select-option>
          <ion-select-option value="2">2</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label>Status</ion-label>
        <ion-select :value="onOff" placeholder="On/Off" ok-text="Ok" cancel-text="Cancel" v-model="onOff">
          <ion-select-option value="on">On</ion-select-option>
          <ion-select-option value="off">Off</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-label>Set Expiry</ion-label>
        <ion-datetime display-format="D MMMM YYYY HH:mm:ss" v-model="expiry" :value="expiry"></ion-datetime>
      </ion-item>
    <ion-button size="medium" @click="circuitTest" fill="outline" expand="block">CIRCUIT CONTROL TEST</ion-button>
    -->
  </div>
</template>

<script>
import {
  IonItem,
  IonIcon,
  IonLabel,
  IonList,
  // IonListHeader,
  // IonSelect,
  // IonSelectOption,
  modalController
} from '@ionic/vue'
import { refreshOutline } from 'ionicons/icons'
import { vedaService } from '../../services/vedaService'
// import { userService } from '@/services/userService'
import UpdateCircuitModal from './UpdateCircuitModal.vue'
import moment from 'moment'

export default {
  name: 'CircuitTest',
  components: {
    IonItem,
    IonIcon,
    IonLabel,
    IonList,
    // IonListHeader,
    // IonSelect,
    // IonSelectOption,
    // IonDatetime,
    // IonToggle
  },
  async mounted() {
    this.refreshCircuitStatus()
    this.moment = moment
    setInterval(() => {
      console.log('CircuitTest:mounted:updateCircuitStatus')
      this.refreshCircuitStatus()
    }, 10000) // Refresh every 10 seconds
  },
  data() {
    return {
      deviceId: vedaService.getVedaDeviceId(),
      satAction: 'forceDeviceCircuit',
      circuits: [],
      refreshOutline
    }
  },
  methods: {
    async getCircuitStatus () {
      return await vedaService.getCircuitStatus(this.deviceId)
    },
    async refreshCircuitStatus() {
      this.circuits = await this.getCircuitStatus()
      this.circuits.forEach(c => {
        c.offExpiry = vedaService.getCircuitExpiry(c.circuitId)
      })
    },
    // async circuitTest (circuitId, status, expiry) {
    //   console.log(`circuitTest:circuitId -> ${circuitId}, status -> ${status}, expiry -> ${expiry}`)
    //   if (!status || !circuitId) {
    //     // alert('Please select CircuitId and Status')
    //     userService.openToast('Please select CircuitId and Status', 'warning')
    //     return
    //   }
    //   // const formattedDate = this.formatDateStringToIso(this.expiry)
    //   const response = await vedaService.circuitControlTest(this.satAction, circuitId.toString(), status, expiry)
    //   if (response.data.status) {
    //     userService.openToast(response.data.data, 'success')
    //   }
    // },
    setExpiryDate (circuitId, expiry) {
      expiry = this.formatDateStringToIso(expiry)
      for (let i = 0; i < this.circuits.length; i++) {
        if (this.circuits[i].circuitId === circuitId) {
          this.circuits[i].expiry = expiry
        }
      }
      console.log('this.circuits ->', this.circuits)
    },
    async openUpdateCircuitModal(circuit) {
      const modal = await modalController
        .create({
          component: UpdateCircuitModal,
          cssClass: 'update-circuit-modal',
          backdropDismiss: true,
          showBackdrop: true,
          componentProps: {
            circuit
          },
        })
      modal.onDidDismiss().then(async () => {
        // await this.getCircuitStatus()
        if (this.debug) console.log('modal.onDidDismiss:refreshing circuit status...')
        await this.refreshCircuitStatus()
      })
      return modal.present()
    }
  }
}
</script>

<style scoped>
  ion-datetime { --placeholder-color: red; }
</style>
