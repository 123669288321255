<template>
  <ion-item><ion-label><b>Test for Wi-Fi -- Sends a ping to device</b></ion-label></ion-item>
  <ion-button size="medium" @click="wifiTest" fill="outline" expand="block">Run Wi-Fi Test</ion-button>
</template>

<script>
import { IonItem, IonLabel } from '@ionic/vue'
import { defineComponent } from 'vue'
import { vedaService } from '../../services/vedaService'
import { userService } from '../../services/userService'
export default defineComponent({
  name: 'WiFiTest',
  components: {
    IonItem,
    IonLabel
  },
  methods: {
    async wifiTest() {
      const deviceId = vedaService.getVedaDeviceId()
      const res = await vedaService.pingDeviceWifi(deviceId, true)
      if (res.isError) { // error while fetching response
        userService.openToast('Unexpected error while pinging VEDA', 'danger')
      } else if (!res.isMqttPresent) { // data is empty
        userService.openToast('Wi-Fi test failed', 'danger')
      } else {
        userService.openToast('Wi-Fi test success', 'success')
      }
    }
  }
})
</script>

<style scoped>
  ion-button {
    text-transform: none;
  }
</style>
