<template>
  <div>
    <ion-item><ion-label><b>Production Tests</b></ion-label></ion-item>
    <ion-card v-if="testDate">
      <ion-card-header>Test Result from {{ moment(testDate).format('MMMM Do YYYY') }}</ion-card-header>
      <ion-card-content>
        <ion-row>
          <ion-col><b>Test Case</b></ion-col>
          <ion-col><b>Test Name</b></ion-col>
          <ion-col><b>Result</b></ion-col>
        </ion-row>
        <ion-row v-for="result in testResults" :key="result.testCase">
          <ion-col>{{ result.testCase }}</ion-col>
          <ion-col>{{ result.testName }}</ion-col>
          <!-- <ion-col :class="result.result.replace(/\//, '')">{{ result.result }}</ion-col> -->
          <ion-col>
            <ion-text color="success" v-if="result.result === 'completed'">{{ result.result }}</ion-text>
            <ion-text color="danger" v-if="result.result === 'failed'">{{ result.result }}</ion-text>
            <ion-text color="medium" v-if="!(['completed', 'failed'].includes(result.result))">{{ result.result }}</ion-text>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import { IonItem, IonLabel } from '@ionic/vue'
import { defineComponent } from 'vue'
import { vedaService } from '../../services/vedaService'
import moment from 'moment'

export default defineComponent({
  name: 'ProdTest',
  components: {
    IonItem,
    IonLabel
  },
  async created() {
    this.moment = moment
    this.runTest()
  },
  data() {
    return {
      testDate: '',
      testResults: []
    }
  },
  methods: {
    async runTest() {
      const response = await vedaService.getProdTests()
      if (response && response.data && response.data.data) {
        const result = response.data.data
        this.testDate = result.prodTestDate
        this.testResults = result.prodTestResults
      }
    }
  }
})
</script>

<style>
  .completed { background-color: green; }
  .failed { background-color: red; }
  .na { background-color: gray; }
</style>
