
import BaseLayout from '../BaseLayout.vue'
import { defineComponent } from 'vue'
import { IonSegment, IonSegmentButton } from "@ionic/vue"

import WiFiTest from '../../components/Test/WiFiTest.vue'
import CircuitTest from '../../components/Test/CircuitTest.vue'
import ProdTest from '../../components/Test/ProdTest.vue'

export default defineComponent({
    name: 'ProductionTest',
    components: {
      BaseLayout,
      WiFiTest,
      CircuitTest,
      ProdTest,
      IonSegmentButton,
      IonSegment,
    },
    data: () => {
      return {
        view: 'cc'
      }
    },
    methods: {
      segmentChanged(event) {
        this.view = event.target.value
      }
    }
})
