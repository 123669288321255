<template>
  <base-layout page-title="Production Test">
    <template v-slot:main-content>
      <div id="container" class="ion-padding">
        <p>Production Tests are tests performed on VEDA during manufacturing. Some of these tests can be repeated</p>

        <ion-segment @ionChange="segmentChanged($event)" color="primary" mode="ios" :value="view">
          <ion-segment-button value="prod">
            <ion-label>Prod Test</ion-label>
          </ion-segment-button>
          <ion-segment-button value="wifi">
            <ion-label>Wi-Fi Test</ion-label>
          </ion-segment-button>
          <ion-segment-button value="cc">
            <ion-label>Circuit Control Test</ion-label>
          </ion-segment-button>
        </ion-segment>

        <div v-if="view=='prod'">
          <ProdTest/>
        </div>
        <div v-if="view=='wifi'">
          <WiFiTest @wifi-test="wifiTest"/>
        </div>
        <div v-if="view=='cc'">
          <CircuitTest/>
        </div>

      </div>
    </template>
  </base-layout>
</template>

<script lang="ts">
import BaseLayout from '../BaseLayout.vue'
import { defineComponent } from 'vue'
import { IonSegment, IonSegmentButton } from "@ionic/vue"

import WiFiTest from '../../components/Test/WiFiTest.vue'
import CircuitTest from '../../components/Test/CircuitTest.vue'
import ProdTest from '../../components/Test/ProdTest.vue'

export default defineComponent({
    name: 'ProductionTest',
    components: {
      BaseLayout,
      WiFiTest,
      CircuitTest,
      ProdTest,
      IonSegmentButton,
      IonSegment,
    },
    data: () => {
      return {
        view: 'cc'
      }
    },
    methods: {
      segmentChanged(event) {
        this.view = event.target.value
      }
    }
})
</script>
